<my-component>
  
   <div>
    <main-player if={state.with_player =="true"}> </main-player>
    <div if={state.with_player =="false"} >
    <tamkin-sdk > </tamkin-sdk>
    </div>
   </div>


   <script >
   

    import main from './main.riot'
    import TaminSDK from './tamkin-sdk.riot';
    const componentElement = document.querySelector('tamkin-player-sdk');
    const version = componentElement.getAttribute('with-player');
    export default { 
        
       components: {
        'main-player':main,
        'tamkin-sdk': TaminSDK
        
      },
     state : {
      with_player:version
     },
     onMounted(){
      console.log("ok");
     },
     
                 

                  
      onUpdated() { 
      },
      alert(e) {
        alert(1)
      }
    }
  </script>


</my-component>